@import url("https://use.typekit.net/vvz4okr.css");


body {
  margin: 0;
  font-family: rift !important;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}
input {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
}


code {
  font-family: rift !important;

}

[type=submit]
{
  font-family: rift;
  font-weight: 600;
  align-items: center;
  text-align: center;
  width: 200px;
  height: 50px;
  border-radius: 18px;
  background-color: #ff3737;
  border: solid 1px transparent;
  color: #fff;
margin-left: 30%;
  font-size: 18px;
  cursor: pointer;
  transition: all .1s ease-in-out;

}

input[type=submit]:hover {
  background-color: black;
}

 



form {
  border-radius: 13px 13px;
  padding: 5px 5px;
  background-color: white;
  border: 2px solid red;

}
