.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.input[type="submit"] {
      width: auto;
    background-color: #000;
    color: white;
    font-weight: 600;
    border-radius: 3px;
    text-align: center;
    align-items: center;
}

.form-input {
      font-size: 16px;
    display: block;
    font-family: rift;
    padding: 10px 1px;
    border: 0;
    font-weight: 600;
    border-bottom: 1px solid #747474;
    outline: none;
    -webkit-transition: all .2s;
    transition: all .2s;
    width: 100% auto;
    background-color: #00;
}

p {
  color: #000;
    padding: 4px;
}

form {
      background: #fff;
    width: 510px;
    margin: 65px auto;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    border-radius: 4px;
    box-shadow: 0 2px 25px rgb(0 0 0 / 20%);
}